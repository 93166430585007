import Touchable from '@components/Touchable/Touchable'
import Title from '@components/Title/Title'
import Logotype from '@components/Logotype/Logotype'
import * as React from 'react'
import * as styles from './404.module.css'

const FourOhFour = (props) => {
  return (
    <div className={styles.root} {...props}>
      <div className={styles.inner}>
        <Touchable disableUnderline to="/" className={styles.title}>
          <div className={styles.ohsnap}>
            <Title variant="h2" tag="span" gutter="sm">
              That's a 404
            </Title>
            <Title variant="h1" tag="span">
              Not Found
            </Title>
          </div>
          <div className={styles.link}>
            <Logotype />
          </div>
        </Touchable>
      </div>
    </div>
  )
}

export default FourOhFour
